import React from 'react';
import {Card, Icon} from 'semantic-ui-react';
import {downloadFilePodById} from "../../api/waybillDocs";
import downloadFile from "../../services/downloadFile";
import {WaybillDocIconEnum, WaybillDocNameEnum} from "../../api/model/Waybill";
import {useTranslation} from "react-i18next";

// import ga from './GalleryArtifact.txt';

export default function GalleryItem({
	url,
	id,
    podType,
    contentType
}) {
    const { t } = useTranslation();

    const iconByContentType = (contentType) => {
        return WaybillDocIconEnum.find(ic => ic.key === (contentType ? contentType : 'file'));
    };

    const headerByPodType = (podType) => {
        return WaybillDocNameEnum.find(ic => ic.key === (podType)).text;
    };

	const downBlob = async (e) => {
		e.preventDefault();
		const res = await downloadFilePodById(id);
		downloadFile(res);
	};

    return (
		<Card
			link
			download
			href={url}
			target="_blank"
			onClick={downBlob}
		>
			<Card.Content>
                {
                    iconByContentType(contentType) &&
                    <Icon
                        size="large"
                        className="f-r"
                        name={iconByContentType(contentType).text}
                        color={iconByContentType(contentType).color}
                    />
                }
				<Card.Header>{t(headerByPodType(podType))}</Card.Header>
				<Card.Description>{t('waybills_btn_download')}</Card.Description>
			</Card.Content>
		</Card>
    );
}
