import {download, get} from '../services/http';

export async function getWaybillDocs(wbId) {

    return await get(`waybills/${wbId}/docs/pod/list`);
}

export async function getSubscriptionDocsTest(clientId) {
    return await get(`subsciption/test/${clientId}/pod`);
}

export async function getSubscriptionStatusTest(clientId) {

    return await get(`subsciption/test/${clientId}/status`);
}

export async function downloadFilePod(id) {

    return await download(`waybills/${id}/docs/pod/download`)
}

export async function downloadFilesByIdsAndType({ podIds }) {
    const query = podIds.map(i => `podIds=${i}`).join('&');
    return await download(`fm/waybill/pod?${query}`);
}

export async function downloadFilePodById(id) {
    return await download(`fm/waybill/pod/${id}`)
}
