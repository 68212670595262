import qs from 'query-string';
import React, {forwardRef, useContext, useRef, useState} from 'react';
import 'moment/locale/ru';
import {withRouter} from 'react-router-dom';
import {Checkbox, Icon, Popup, Table} from 'semantic-ui-react';
import ScrollPreloader from '../../../components/miscs/ScrollPreloader';
import WbGridRowsElement from './WbGridRowsElement';
import {ContextUser} from '../../../services/context';
import UserPermissions from '../../../api/model/UserPermissions';
import {getAll} from '../../../api/waybills';
import {
    WaybillDocTypeEnum,
    WaybillStatusAnomalyOptions,
    WaybillStatusAvizationOptions,
    WaybillStatusEnum,
    WaybillStatusOptions,
    WaybillTypeOptions
} from '../../../api/model/Waybill';
import WbGridFilterStringContains from "./wbGridFilters/WbGridFilterStringContains";
import WbGridFilterDatePeriod from "./wbGridFilters/WbGridFilterDatePeriod";
import WbGridFilterString from "./wbGridFilters/WbGridFilterString";
import WbGridFilterList from "./wbGridFilters/WbGridFilterList";
import O from "../../../components/Option";
import iconClearFilters from "./WbGridHeadersClearFiltersIcon.png";
import WbGridFilterListMulti from "./wbGridFilters/WbGridFilterListMulti";
import {viewWaybillsNotification} from "../../../api/users";
import WbGridFilterDateTime from "./wbGridFilters/WbGridFilterDateTime";
import { useTranslation } from 'react-i18next';
import {downloadFilePodById} from "../../../api/waybillDocs";
import downloadFile from "../../../services/downloadFile";
import {copyTextFromEvent} from "../../../services/utils";
import {toast} from "../../../services/toast";


const WbGridTable = forwardRef(({setLoading, headers, rows, setRows, selectedIds, selectIds, location, history, getRowsIds, fixed, scrollComponent}, ref = {}) => {
    const { t } = useTranslation();

    const page = useRef(0);

    const pageSize = 70;

    const [scrollLoading, setScrollLoading] = useState(false);

    const contextUser = useContext(ContextUser);

    const canEdit = contextUser.current.permissions.includes(UserPermissions.WAYBILL_EDIT);

    function handleRowSelection(id, rows, e, { checked }) {

        if (e.nativeEvent.shiftKey && selectedIds.length > 0 && checked) {

            const lastSelectedId = selectedIds[selectedIds.length - 1];

            const previousIndex = rows.findIndex(r => r.id === lastSelectedId) || 0;
            const currentIndex = rows.findIndex(r => r.id === id) || 0;

            const idsScope = [];

            if (currentIndex > previousIndex) {
                for (let index = previousIndex; index <= currentIndex; index++) {

                    idsScope.push(rows[index].id);
                }
            } else {
                for (let index = previousIndex; index >= currentIndex; index--) {

                    idsScope.push(rows[index].id);
                }
            }

            selectIds([...idsScope]);
        }
        else {
            if (checked) {
                selectIds([...selectedIds, id]);
            }
            else {
                selectIds([...selectedIds.filter(i => i !== id)]);
            }
        }

    }

    async function handleRowClick(r) {
        const isEditable = r.status === WaybillStatusEnum.DRAFT;
        const suffix = isEditable // (isEditable || canEdit)
            ? "edit"
            : "view/info";
        history.push(`/waybills/${r.id}/${suffix}`);
        await viewWaybillsNotification(r.id);
    };

    async function onBottomVisible() {
        if (scrollLoading) {
            return;
        }
        const nextPage = page.current + 1;

        const filter = qs.parse(location.search);

        filter.page = nextPage;

        const filterString = qs.stringify(filter);

        setScrollLoading(true);
        const nextRows = await getAll("?" + filterString);

        if (nextRows && nextRows.length) {

            const allRows = rows.concat(nextRows);

            setRows(allRows);

            page.current = nextPage;
        }
        setScrollLoading(false);
    }

    const noRows = (
        <div className="no-rows-wrapper">
            <div className="no-rows">{t('waybills_label_no-orders')}</div>
        </div>
    );

    const keys = headers.map(h => h.key);

    const needScroll = rows.length >= pageSize && rows.length % pageSize === 0;

    const firstLoad = false;

    let query = qs.parse(location.search);

    function updateQuery(q) {
        query = q;
        history.push('?' + qs.stringify(query));
    }

    const handleSort = clickedColumn => () => {
        let { sortBy, sortDir } = query;

        if (sortBy !== clickedColumn) {
            sortBy = clickedColumn;
            sortDir = 'ascending';
        } else {
            sortDir = sortDir === 'ascending' ? 'descending' : 'ascending';
        }

        updateQuery({ ...query, sortBy, sortDir });
    };

    const scrollTop = () => {
        page.current = 0;
        scrollComponent.current.scrollTop = 0;
    };


    function applyFilter(filter) {
        scrollTop();
        updateQuery({ ...query, ...filter });
        selectionExist && selectIds([]);

    }

    function removeFilter(key) {
        scrollTop();
        updateQuery({ ...query, [key]: undefined });
        selectionExist && selectIds([]);
    }

    function clearFilters() {
        scrollTop();
        updateQuery({});
        selectionExist && selectIds([]);
    }

    function getFilter(key) {
        if (firstLoad)
            return null;

        switch (key) {
            case 'fmid':
            case "returnWaybillId":
            case 'clientId':
            case 'clientName':
            case 'shipper':
            case 'refs':
            case 'torg12No':
            case 'consignee':
            case 'shipto':
            case 'slotId':
            case 'reservationId':
            case 'visitId':
            case 'providerName':
            case 'reservationDriver':
            case 'driver':
            case 'phoneNumber':
            case 'trailer':
            case 'trailerDimensions':
            case 'trailerWeight':
            case 'truck':
            case 'truckDimensions':
            case 'truckWeight':
                return <WbGridFilterStringContains
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'reservation':
                return <WbGridFilterDateTime
                    fieldDate="visitDate"
                    fieldTimeFrom="timeslotStart"
                    fieldTimeTo="timeslotEnd"
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                />;
            case 'dateCreated':
            case 'loadingDate':
            case 'deliveryDate':
            case 'unloadingDateDeparture':
                return <WbGridFilterDatePeriod
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter} />;
            case 'cost':
            case 'totalWeight':
            case 'totalUnits':
                return <WbGridFilterString
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter} />;
            case 'type':
                return <WbGridFilterList
                    field="type"
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillTypeOptions} />;
            case 'status':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusOptions} />;
            case 'avisationStatus':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusAvizationOptions} />;
            case 'statusAnomaly':
                return <WbGridFilterListMulti
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={WaybillStatusAnomalyOptions} />;
            case 'document':
            case 'services':
                return <WbGridFilterList
                    field={key}
                    applyFilter={applyFilter}
                    removeFilter={removeFilter}
                    options={[
                        new O('false', t('base_enum_no')),
                        new O('true', t('base_enum_there_is'))
                    ]} />;
            default:
                return <div
                    className="filter-flex-container"></div>;
        }
    }

    function deselect() {
        if (selectionExist) {
            selectIds([]);
        }
        else {
            selectIds(getRowsIds());
        }
    }

    const { sortBy, sortDir } = query;

    const selectionExist = selectedIds.length > 0;

    const clearFilterButton = (
        <span
            className="table-clear-filters"
            onClick={clearFilters}
            style={{ paddingTop: '5px', display: 'inline-block', width: '17px' }}>
            <img
                src={iconClearFilters}
                alt="icf"
                style={{ height: '13px' }} />
        </span>
    );

    const styleDisabledHeader = {
        background: '#f9fafb',
        cursor: 'default'
    };

    const selectorDisabled = selectionExist
        ? false
        : !Object.keys(query).length;

    const copyData = (e) => {
        e.stopPropagation();
        copyTextFromEvent(e);
        toast.success(t('base_label_copied'));
    };

    const download = async (id) => {
        const res = await downloadFilePodById(id);
        downloadFile(res);
    };

    const docTableCell = (r, type, icon, nameBtn, isDouble) => {

        const doc = r.documents.find(d=>d.podType === type) || {};

        return <Table.Cell
            rowSpan={isDouble && 2}
            textAlign='center'
            onClick={() => download(doc.podId)}
            disabled={!(doc.isAvailable)}
            key={type}
            className={`${doc.isHighlited && 'table-document__highlited'}`}
        >
            <Popup content={t(nameBtn)} trigger={<Icon name={icon} size='big' color={doc.isAvailable ? 'green' : 'grey'}/>} />
        </Table.Cell>;
    };

    const docs = (r, isDouble) => {
        return <>
            {docTableCell(r, WaybillDocTypeEnum.LABEL, 'barcode', 'waybills_btn_download-marking', isDouble)}
            {docTableCell(r, WaybillDocTypeEnum.TTN, 'file alternative', 'waybills_btn_download-ttn', isDouble)}
        </>;
    };

    return (
        <>
            <Table celled sortable fixed={fixed} singleLine>
                <Table.Header className="table-header-fixed">
                    <Table.Row key="headers" style={{ fontSize: 'smaller' }}>
                        <Table.HeaderCell className="table-first-col table-select-all-cell table-item-sticky">
                            <Checkbox
                                checked={false}
                                //disabled={!selectionExist}
                                disabled={selectorDisabled}
                                indeterminate={selectionExist}
                                onChange={(e, data) => deselect()} />
                        </Table.HeaderCell>
                        {headers.map(({ text, key, sortable }) => (
                            <Table.HeaderCell
                                colSpan={key === 'documents' && 2}
                                id={`dr-auto-info_${key}`}
                                key={key}
                                className={key === 'fmid' ? 'table-select-cell table-header-fixed__title-fmid': 'table-select-cell table-header-fixed__title'}
                                sorted={sortable && sortBy === key ? sortDir : null}
                                style={sortable ? null : styleDisabledHeader}
                                onClick={sortable ? handleSort(key) : null}
                            >
                                <span>{t(text)}</span>
                            </Table.HeaderCell>
                        ))}
                    </Table.Row>
                    <Table.Row key="filters" style={{height: "37px"}} >
                        <Table.Cell
                            width={1}
                            className="table-filter-cell table-select-clear-filter table-item-sticky"
                            style={{ backgroundColor: '#f9fafb', textAlign: 'center', paddingTop: '5px', paddingBottom: '5px'}}>
                            <Popup content={t('base_btn_clear-all-filters')} trigger={clearFilterButton} />
                        </Table.Cell>
                        {headers.map(({ key, filter }) => (
                            <Table.Cell
                                colSpan={key === 'documents' && 2}
                                width={key==='shipto' ? 1 : null}
                                key={key}
                                sorted={sortBy === key ? sortDir : null}
                                className={key === 'fmid' ? 'table-filter-cell table-header-fixed__filter-fmid': 'table-filter-cell table-header-fixed__filter'}
                            >
                                {getFilter(key)}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                </Table.Header>
                { rows.length ?  <Table.Body>
                    {rows.map(r => {
                        const arrayRows = ['driver','phoneNumber','trailer','trailerDimensions','trailerWeight','truck','truckDimensions','truckWeight'];
                        const isDouble = r.shippings && r.shippings.length > 1;
                        return <>
                            <Table.Row key={r.id}
                                       style={{cursor: 'pointer'}}
                                       active={selectedIds.includes(r.id)}
                            >
                                <Table.Cell className="table-first-col table-item-sticky" rowSpan={isDouble && '2'}>
                                    <Checkbox id={r.id}
                                              checked={selectedIds.includes(r.id)}
                                              onChange={handleRowSelection.bind(null, r.id, rows)}/>
                                </Table.Cell>
                                {keys.map(key =>
                                    (key !== 'documents' ? <Table.Cell
                                            rowSpan={(isDouble && !arrayRows.includes(key)) && '2'}
                                            className={key === 'fmid' ? 'table-item-fmid-sticky' : `${arrayRows.includes(key) && 'table-cell_driver-auto'}`}
                                            onClick={arrayRows.includes(key) ? copyData : () => handleRowClick(r)}
                                            key={key}>
                                            <WbGridRowsElement key={key} el={key} data={r}
                                                               canEdit={canEdit}/>
                                        </Table.Cell> : docs(r, isDouble)
                                    ))}
                            </Table.Row>

                            {isDouble &&
                            <Table.Row key={r.id+'_1'}
                                       style={{cursor: 'pointer'}}
                                       active={selectedIds.includes(r.id)}
                            >
                                {keys.map(key => arrayRows.includes(key) && <Table.Cell
                                    className='table-cell_driver-auto'
                                    onClick={arrayRows.includes(key) ? copyData : () => handleRowClick(r)}
                                    key={key}><WbGridRowsElement key={key} el={key} data={r} canEdit={canEdit}
                                                                 isDouble/></Table.Cell>)}
                            </Table.Row>
                            }
                        </>;
                    })}
                </Table.Body> : <div className="table-empty"></div>}

            </Table>
            {!rows.length && noRows}
            {needScroll && <ScrollPreloader onVisible={onBottomVisible} continuous={true} />}
        </>

    );
});

export default withRouter(WbGridTable);
