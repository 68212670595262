import qs from 'query-string';
import React, {useContext, useState} from 'react';
import {Confirm, Divider, Icon, Menu} from 'semantic-ui-react';
import {PickupTypeEnum} from '../../../../api/model/Enums';
import Waybill, {
    nonDeleteErrorAvisStatusSet,
    nonDeleteErrorStatusSet,
    WaybillAvisationStatus,
    WaybillDocTypeEnum,
    WaybillStatusAvizationEnum,
    WaybillStatusEnum,
    WaybillTypeEnum
} from '../../../../api/model/Waybill';
import WaybillPoint from '../../../../api/model/WaybillPoint';
import {
    cancelWaybills,
    copyWaybill,
    createWaybill,
    waybillDelete,
    waybillExport,
    waybillExportAll,
    waybillsAvizo,
    waybillsDeleteSoft
} from '../../../../api/waybills';
import {createPickup2} from '../../../../api/waybillsMerge';
import {toast} from '../../../../services/toast';
import WbProcessing from '../../common/WbProcessing';
import WbPointAddForm from '../../wbEdit/WbPointAddForm';
import WbColumnsSetup from './wbColumnsSetup/WbColumnsSetup';
import './WbGridToolbar.css';
import WbsImportForm from './WbsImportForm';
import UserPermissions from "../../../../api/model/UserPermissions";
import {ContextGridSettings, ContextUser} from "../../../../services/context";
import moment from "moment";
import useReactRouter from 'use-react-router';
import { useTranslation } from 'react-i18next';
import { downloadFilesByIdsAndType } from "../../../../api/waybillDocs";
import downloadFile from "../../../../services/downloadFile";


export default function OrdersToolbar({selectedRows, setSelectedIds, refresh, loading, updateFMIDs, hasRows, setIsOpenAvizModal}) {
    const { t } = useTranslation();

    const { history } = useReactRouter();
    const [isFormOpen, setIsFormOpen] = useState(false);

    const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
    const contextGridSettings = useContext(ContextGridSettings);
    const contextUser = useContext(ContextUser);

    const noSelection = selectedRows.length === 0;
    const selectedIds = selectedRows.map(r => r.id);
    const isFirstLoad = false;

    const canSend = selectedRows.length && selectedRows.every(wb => wb.status === WaybillStatusEnum.DRAFT && wb.avisationStatus !== WaybillStatusAvizationEnum.REQUIRES && wb.isValid);

    const canChangeShippingMethod = selectedRows.length &&
        selectedRows.every(wb => wb.status === WaybillStatusEnum.DRAFT
            && wb.avisationStatus === WaybillStatusAvizationEnum.REQUIRES
            && wb.isValid && !(wb.pickupType === 5 && !wb.crossDockWithActivity));

    const canAvizoOld = selectedRows.length && selectedRows.every(wb => {
        return wb.hubWithOldAvization && wb.canHaveAvisation
            && wb.avisationStatus === WaybillStatusAvizationEnum.REQUIRES;
    });
    const canAvizo = selectedRows.length && selectedRows.every(wb => {
        return wb.crossDock && wb.canHaveAvisation &&
            wb.avisationStatus === WaybillStatusAvizationEnum.REQUIRES &&
            wb.clientId === selectedRows[0].clientId &&
            !contextUser.current.permissions.includes(UserPermissions.DISALLOW_AVISATION);
    }) && Array.from(new Set(selectedRows.map(wb => wb.crossDock))).length === 1;

    const nonCancellableStatusSet = [
        WaybillStatusEnum.EXECUTING,
        WaybillStatusEnum.DRAFT,
        WaybillStatusEnum.CARGO_DELIVERED,
        WaybillStatusEnum.CANCELLED
    ];

    const nonDownloadDocsStatusSet = [
        WaybillStatusEnum.DRAFT,
        WaybillStatusEnum.SUBMITTED,
        WaybillStatusEnum.ON_APPROVAL
    ];

    const canDownload = selectedRows.length > 1 && selectedRows.every(wb => !nonDownloadDocsStatusSet.includes(wb.status));
    const canDownloadMarking = canDownload && selectedRows.every(wb => wb.documents && wb.documents.find(d=>d.podType === WaybillDocTypeEnum.LABEL));
    const canDownloadTtn = canDownload && selectedRows.every(wb => wb.documents && wb.documents.find(d=>d.podType === WaybillDocTypeEnum.TTN));
    const canDownloadAll = canDownloadMarking && canDownloadTtn;

    const canResend = selectedRows.length > 0 && selectedRows.every(wb => wb.status === WaybillStatusEnum.SUBMITTED) && contextUser.current.permissions.includes(UserPermissions.WAYBILLS_RESEND);

    const canCancel =
        selectedRows.length === 1 &&
        (selectedRows.every(wb => !nonCancellableStatusSet.includes(wb.status) && !wb.isPooling && wb.type !== WaybillTypeEnum.Return)
        || selectedRows.every(wb => wb.type === WaybillTypeEnum.Return && contextUser.current.permissions.includes(UserPermissions.RETURN_WAYBILLS_EDIT)
                && [WaybillStatusEnum.EXECUTING, WaybillStatusEnum.SUBMITTED, WaybillStatusEnum.ON_APPROVAL].includes(wb.status)));

    const canDelete = selectedRows.length > 0 && selectedRows.every(wb => wb.status === WaybillStatusEnum.DRAFT);

    const canExport = selectedRows.length > 0 && selectedRows.every(wb => wb.status !== WaybillStatusEnum.DRAFT);

    let canExportAll = selectedRows.length === 0 && Object.keys(qs.parse(history.location.search)).length !== 0;
    if (qs.parse(history.location.search).status) {
        canExportAll = canExportAll && !qs.parse(history.location.search).status.split(',').includes(`${WaybillStatusEnum.DRAFT}`);
    }

    const canDeleteErroneous = selectedRows.length > 0 &&
        contextUser.current.permissions.includes(UserPermissions.WAYBILL_SOFT_DELETE) &&
        selectedRows.every(wb => nonDeleteErrorStatusSet.includes(wb.status)) &&
        selectedRows.every(wb => nonDeleteErrorAvisStatusSet.includes(wb.avisationStatus)) &&
        selectedRows.every(wb => !wb.isPooling) &&
        selectedRows.every(wb => wb.type === WaybillTypeEnum.Return ? contextUser.current.permissions.includes(UserPermissions.RETURN_WAYBILLS_EDIT) : true);

    const setChangeLoading = (value) => {
        return new Promise((resolve) => {
            loading(value);
            resolve();
        });
    };
    const promiseSetSelectedIds= (list) => {
        return new Promise((resolve) => {
            setSelectedIds(list);
            resolve();
        });
    };

    const cancel = async () => {

        await setChangeLoading(true);
        const ids = await cancelWaybills(selectedRows.map(sr => sr.id));

        if (ids && ids.length === selectedRows.length) {
            selectedRows.forEach(wb => {
                wb.status = WaybillStatusEnum.CANCELLED;
            });
        }

        if (ids && ids.length > 0) {
            await setChangeLoading(false);
            await promiseSetSelectedIds(ids);
            setIsOpenAvizModal(true);

        } else {
            await refresh();
        }

    };

    const canPickupStatusSet = [
        WaybillStatusEnum.ON_APPROVAL,
    ];
    const canPickup = !noSelection && (selectedRows.every(i => i.type === WaybillTypeEnum.Return) || selectedRows.every(i =>
        i.type === WaybillTypeEnum.LTL &&
        canPickupStatusSet.includes(i.status)
    ) && selectedRows.every((i) => {
        return i.pickupType !== PickupTypeEnum.OTHER;
    }));

    const createPickupLocal = async (point) => {

        loading(true);

        try {

            const id = await createPickup2(point.pointId, selectedIds);

            if (id) {

                const url = `/waybills/${id}/edit`;

                history.push(url);
            } else {

                loading(false);

                toast.error(t('waybills_label_id-error'), t('waybills_label_error-create-ftl'));
            }

        } catch (error) {

            loading(false);
        }
    };

    const numberStyle = {
        fontSize: '15px',
        //fontWeight: 'bold'
    };
    const descrStyle = {
        color: '#9e9e9e'
    };

    const totalWeight = selectedRows
        ? selectedRows.reduce((acc, row) => acc + Number.parseInt(row.totalWeight || 0), 0)
        : null;

    const totalUnits = selectedRows && selectedRows.length
        ? selectedRows.reduce((x, r) => x += Object.keys(r.totalUnits).reduce((x2, key) => x2 += r.totalUnits[key], 0), 0)
        : 0;


    const iconName = "weight"; //"clipboard check";

    const canCopy = selectedIds && selectedIds.length === 1 && selectedRows[0].canCopy;

    const create = async () => {

        loading(true);

        const query = qs.parse(history.location.search) || {};

        const type = query.type || "LTL";

        const dto = await createWaybill(new Waybill({
            type: type,
        }));

        if (dto && dto.id) {
            history.push(`/waybills/${dto.id}/edit`);
        } else {
            loading(false);
        }
    };

    const createCopy = async () => {

        loading(true);
        try {
            const newId = await copyWaybill(selectedIds[0]);

            history.push(`/waybills/${newId}/edit`);
        }catch(err) {

            loading(false);
        }
    };

    const downloadFiles = async (type) => {
        let rowDocs = selectedRows.map(row => row.documents);
        let docsIds = rowDocs.flat().filter(d => d.podType !== 0 && (type ? d.podType === type : true)).map(d => d.podId);
        const res = await downloadFilesByIdsAndType({ podIds: docsIds });
        downloadFile(res);
    };

    const onCLickDeleteErroneous = async () => {
        loading(true);
        try {
            const wbIds = selectedRows.map(sr => sr.id);
            await waybillsDeleteSoft(wbIds);
            refresh();
        } finally {
            loading(false);
        }
    };

    const waybillExportClick = async (event, data) => {
        loading(true);
        try {
            if (event.ctrlKey || event.metaKey) {
                window.open(`/api/waybill/${selectedIds[0]}/export/json`, "_blank");
            } else {
                var fileInfo = await waybillExport(selectedIds);
                if (!fileInfo.error) {
                    window.open(`/api/file/${fileInfo.id}`, "_blank");
                }
            }
        } finally {
                loading(false);
        }
    };

    const waybillExportAllClick = async (event, data) => {
        loading(true);
        const query = history.location.search;
        try {
            if (event.ctrlKey || event.metaKey) {
                window.open(`/api/waybill/${selectedIds[0]}/export/json`, "_blank");
            } else {
                let fileInfo = await waybillExportAll(query);
                if (!fileInfo.error) {
                    window.open(`/api/file/${fileInfo.id}`, "_blank");
                }
            }
        } finally {
            loading(false);
        }
    };

    async function avizo() {
        loading(true);
        try {
            await waybillsAvizo(selectedIds);
            selectedRows.forEach(sr => {
                sr.avisationStatus = WaybillAvisationStatus.REQUESTED;
            });
        } finally {
            loading(false);
        }
    }

    function onClickAvizoOpenModal() {
        if (contextUser.current.companies.length> 1 && selectedRows.filter(function (elem, pos, arr) {
            return  arr.filter(i => i.clientId === elem.clientId).length !== arr.length;
        }).length) {
            toast.error(t('waybills_label_error-advise-diff-companies'));
            return;
        }
        if (moment(selectedRows.map(i => new Date(i.loadingDate)).sort((a, b) => a - b)[0]).format('YYYY-MM-DD') < moment().format('YYYY-MM-DD')) {
            toast.error(t('waybills_label_error-advise-dates'));
            return;
        }
       setIsOpenAvizModal(true)
    }

    async function waybillDeleteLocal() {
        setIsDeleteConfirmOpen(false);
        loading(true);
        try {
            const wbIds = selectedRows.map(sr => sr.id);
            await waybillDelete(wbIds);
            refresh();
        } finally {
            loading(false);
        }
    }

    const onFormClose = () => {
        setIsFormOpen(false);
    };

    return (
        <Menu className="waybills-toolbar shd-inset" style={{marginBottom: '0'}} size='small' borderless>
            <Menu.Item as="div" className="p-t-0 p-b-0" style={{color: selectedIds.length ? 'inherit' : '#9e9e9e'}}>
                <Icon
                    name={selectedIds.length ? iconName : iconName}
                    style={{
                        fontSize: "15px",
                        marginLeft: "7px",
                        color: selectedIds.length ? '#106ebe' : '#9e9e9e',
                        marginTop: "-2px"
                    }}
                />
                &nbsp;<span style={numberStyle}>{totalUnits}</span>&nbsp;<span style={descrStyle}>{t('waybills_label_freight-units')}</span>
                &nbsp;<span style={numberStyle}>{totalWeight}</span>&nbsp;<span style={descrStyle}>{t('waybills_label_kg')}</span>
            </Menu.Item>
            <Menu.Menu position='right'>
                {<Menu.Item title={t('waybills_label_advise-by-email')} onClick={avizo} disabled={!canAvizoOld}>
                    <Icon name="clock outline" />{t('waybills_btn_advise-by-email')}
                </Menu.Item>
                }
                <Menu.Item onClick={onClickAvizoOpenModal} disabled={!canAvizo}>
                    <Icon name="clock outline"/>{t('waybills_btn_advise')}
                </Menu.Item>
                <Menu.Item
                    //onClick={createPickupLocal}
                    disabled={!canPickup}
                    title={t('waybills_btn_create-ftl-order-from-ltl')}
                    onClick={() => setIsFormOpen(true)}
                >
                    <Icon name="truck" disabled={!canPickup}/>{t('waybills_btn_create-pickup')}
                </Menu.Item>
                <WbPointAddForm
                    profile="pointsLoading"
                    wbClientId={selectedRows.length && selectedRows[0].clientId}
                    pointsExistingIds={[]}
                    submit={createPickupLocal}
                    value={new WaybillPoint()}
                    open={isFormOpen}
                    onClose={onFormClose}
                >
                </WbPointAddForm>
                <WbProcessing type={"FTL"} selectedRows={selectedRows} updateFMIDs={updateFMIDs} refresh={refresh}>
                    <Menu.Item disabled={!canSend} title={t('waybills_btn_check-send-orders-for-execution')}>
                        <Icon name="upload" disabled={!canSend}/>{t('waybills_btn_for-execution')}
                    </Menu.Item>
                </WbProcessing>
                <WbProcessing type={"FTL"} selectedRows={selectedRows} updateFMIDs={updateFMIDs} refresh={refresh}>
                    <Menu.Item disabled={!canChangeShippingMethod} title={t('waybills_btn_change-delivery-method-send-orders')}>
                        <Icon name="upload" disabled={!canChangeShippingMethod}/>{t('waybills_btn_change-delivery-method')}
                    </Menu.Item>
                </WbProcessing>
                <WbProcessing selectedRows={selectedRows} isResend refresh={refresh}>
                    <Menu.Item disabled={!canResend}>
                        <Icon name="star" disabled={!canResend} />{t('waybills_btn_resending-to-1c')}
                    </Menu.Item>
                </WbProcessing>
                <Menu.Item onClick={createCopy} disabled={!canCopy}>
                    <Icon name="copy"/>{t('waybills_btn_create-copy-order')}
                </Menu.Item>
                <Menu.Item onClick={() => setIsDeleteConfirmOpen(true)} disabled={!canDelete}>
                    <Icon name="trash"/>{t('waybills_btn_del-drafts')}
                </Menu.Item>
                <Menu.Item onClick={onCLickDeleteErroneous} disabled={!canDeleteErroneous}>
                    <Icon name="trash"/>{t('waybills_btn_del-erroneous')}
                </Menu.Item>
                <Confirm open={isDeleteConfirmOpen}
                         dimmer="inverted"
                         header={t('waybills_label_del-drafts')}
                         content={t('waybills_label_del-drafts-question')}
                         confirmButton={t('base_btn_delete')}
                         cancelButton={t('base_btn_cancel')}
                         onCancel={() => setIsDeleteConfirmOpen(false)}
                         onConfirm={waybillDeleteLocal} />
                <Menu.Item onClick={() => downloadFiles()} disabled={!canDownloadAll}>
                    <Icon name="download" />{t('waybills_btn_download-all')}
                </Menu.Item>
                <Menu.Item onClick={() => downloadFiles(WaybillDocTypeEnum.LABEL)} disabled={!canDownloadMarking}>
                    <Icon name="download" />{t('waybills_btn_download-markings')}
                </Menu.Item>
                <Menu.Item onClick={() => downloadFiles(WaybillDocTypeEnum.TTN)} disabled={!canDownloadTtn}>
                    <Icon name="download" />{t('waybills_btn_download-ttns')}
                </Menu.Item>
                <Menu.Item onClick={create} disabled={!noSelection}
                           className={isFirstLoad ? "waybill-button-highlight" : ""}>
                    <Icon name="plus"/>{t('waybills_btn_new-order')}
                </Menu.Item>
                <WbsImportForm refresh={refresh}>
                    <Menu.Item title={t('waybills_label_import-orders-excel')} disabled={!noSelection}>
                        <Icon name="arrow down"/>{t('waybills_btn_import-orders')}
                    </Menu.Item>
                </WbsImportForm>
                <Menu.Item
                    onClick={waybillExportClick}
                    disabled={!canExport}
                    title={t('waybills_label_export-orders-excel')}>
                    <Icon disabled={!canExport} name="arrow up"/>{t('waybills_btn_export-orders')}
                </Menu.Item>
                <Menu.Item
                    onClick={waybillExportAllClick}
                    disabled={!canExportAll}
                    title={t('waybills_label_export-filter-all-orders-excel')}>
                    <Icon disabled={!canExportAll} name="arrow up"/>{t('waybills_btn_export-orders')}
                </Menu.Item>
                <Menu.Item fitted>
                    <Divider vertical/>
                </Menu.Item>
                <WbColumnsSetup>
                    <Menu.Item style={{display: 'inherit'}} title={t('waybills_label_settings-fields')}>
                        <Icon name="setting"/>
                    </Menu.Item>
                </WbColumnsSetup>
            </Menu.Menu>
        </Menu>
    );

}
