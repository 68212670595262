export default class WaybillColumn {
    /**
     * @param {string} key
     * @param {string} text
     * @param {number} order
     * @param {boolean} sortable
     * @param {boolean} [hide]
     * @param {boolean} [system]
     */
    constructor(key, text, order, sortable = false, hide = false, system = false) {
        this.key = key;
        this.text = text;
        this.order = order;
        this.sortable = sortable;
        this.hide = hide;
        this.system = system;
    }
}

export const waybillColumnsSet = [
    new WaybillColumn("fmid", "waybills_grid_id", 1, true, false, true),
    new WaybillColumn("documents", "waybills_grid_print_forms", 2, false, false),
    new WaybillColumn("returnWaybillId", "waybills_grid_return-id", 2, true, false, true),
    new WaybillColumn("slotId", "waybills_grid_slot-num", 3, true, true),
    new WaybillColumn("clientId", "waybills_grid_client-searchname", 4, false, true),
    new WaybillColumn("providerName", "waybills_grid_provider", 5, false, true),
    new WaybillColumn("clientName", "waybills_grid_client-name", 6, false, false),
	new WaybillColumn("type", "waybills_grid_app-type", 7, false),
	new WaybillColumn("shipper", "waybills_grid_shipper", 8, false),
    new WaybillColumn("refs", "waybills_grid_order-nums", 9),
    new WaybillColumn("torg12No", "waybills_grid_torg12", 10),
    new WaybillColumn("totalWeight", "waybills_grid_weight-kg", 11, true),
    new WaybillColumn("totalUnits", "waybills_grid_cargo-units", 12, true),
    new WaybillColumn("cost", "waybills_grid_cargo-cost", 13, true),
    new WaybillColumn("avisationStatus", "waybills_grid_aviz-status", 14, true, true),
	new WaybillColumn("status", "waybills_grid_status", 15, true),
    new WaybillColumn("consignee", "waybills_grid_consignee", 16),
    new WaybillColumn("driver", "waybills_grid_driver_info", 17, false),
    new WaybillColumn("phoneNumber", "waybills_grid_driver_phone_number", 18, false),
    new WaybillColumn("truck", "waybills_grid_truck", 19, false),
    new WaybillColumn("truckDimensions", "waybills_grid_truck_dimensions", 20, false),
    new WaybillColumn("truckWeight", "waybills_grid_truck_weight", 21, false),
    new WaybillColumn("trailer", "waybills_grid_trailer", 22, false),
    new WaybillColumn("trailerDimensions", "waybills_grid_trailer_dimensions", 23, false),
    new WaybillColumn("trailerWeight", "waybills_grid_trailer_weight", 24, false),
    new WaybillColumn("shipto", "waybills_grid_delivery-address", 155),
    new WaybillColumn("loadingDate", "waybills_grid_load-date", 25, true),
    new WaybillColumn("deliveryDate", "waybills_grid_date-delivery", 26, true),
    new WaybillColumn("unloadingDateDeparture", "waybills_grid_actual-date-delivery", 175, false),
	new WaybillColumn("readiness", "waybills_grid_readiness-proc", 27, false, true),
	new WaybillColumn("document", "waybills_grid_docs", 28, false, true),
	new WaybillColumn("dateCreated", "waybills_grid_create-order-date", 29, true, false),
    new WaybillColumn("statusAnomaly", "waybills_grid_anomaly-status", 30, false, false),
    new WaybillColumn("reservationId", "waybills_grid_visit-num", 31, true, true),
	new WaybillColumn("services", "waybills_grid_services", 32, false, false),
	new WaybillColumn("reservation", "waybills_grid_timeslot", 40, true, true),
	new WaybillColumn("reservationDriver", "waybills_grid_driver", 50, true, true),
];
